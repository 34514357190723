import React, { FC } from 'react';
import {
  Edit,
  SaveButton,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRedirect,
} from 'react-admin';
import { FollowUpForm } from '../components/FollowUpForm';
import HeaderTitle from '../../../Shared/HeaderTitle/HeaderTitle';
import ResourceDetailTopToolbar from '../../../Shared/ResourceDetailTopToolbar/ResourceDetailTopToolbar';

const EditActions: React.FC<ToolbarProps> = (props) => (
  <Toolbar
    {...props}
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 24px 0 24px !important',
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    }}
  >
    <SaveButton />
  </Toolbar>
);

const FollowUpEdit: FC = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (): void => {
    notify(`Story Follow-Up updated successfully`);
    redirect('list', 'storymessages');
  };

  return (
    <Edit
      actions={
        <ResourceDetailTopToolbar
          resource="Story Follow-Up"
          hasShowButton
          hasBackButton
          hasDeleteButton
        />
      }
      title={<HeaderTitle />}
      mutationOptions={{ onSuccess }}
      transform={(data: any) => {
        const { id, createdAt, ...rest } = data;
        return rest;
      }}
      {...props}
    >
      <FollowUpForm toolbar={<EditActions />} />
    </Edit>
  );
};

export default FollowUpEdit;
